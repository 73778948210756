<template>
  <v-dialog :value="value" @input="close" max-width="600px">
    <v-card>
      <!-- Title -->
      <v-card-title>
        <h3 v-if="item">{{ $t("studies.mainDialog.edit.title") }}</h3>
        <h3 v-else>{{ $t("studies.mainDialog.add.title") }}</h3>
      </v-card-title>

      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row class="mt-12">
            <!-- Kind -->
            <v-col cols="12" v-if="item == null">
              <v-select
                outlined
                hide-details
                :label="$t('studies.labels.kind')"
                :items="$t('constants.studyKinds.list')"
                item-value="value"
                item-text="text"
                v-model="form.kind"
              />
            </v-col>

            <!-- Product -->
            <v-col cols="12" v-if="form.kind === 'PRODUCT' && item == null">
              <SelectProduct
                :value="form.product"
                :account-id="accountId"
                :is-required="true"
                @input="onInputProduct"
              />
            </v-col>

            <!-- Component -->
            <v-col
              cols="12"
              v-else-if="form.kind === 'COMPONENT' && item == null"
            >
              <SelectComponent
                :value="form.component"
                :account-id="accountId"
                :is-required="true"
                @input="onInputComponent"
              />
            </v-col>

            <!-- Account -->
            <v-col cols="12" v-if="item == null && form.kind !== 'PRODUCT'">
              <SelectAccount
                :value="form.account"
                :is-required="true"
                @input="onInputAccount"
              />
            </v-col>

            <!-- Type -->
            <v-col cols="12">
              <v-select
                outlined
                hide-details
                :label="$t('studies.labels.type')"
                :items="$t('constants.studyTypes.list')"
                item-value="value"
                item-text="text"
                v-model="form.type"
              />
            </v-col>

            <!-- SKU -->
            <v-col cols="12">
              <v-text-field
                hide-details
                :label="$t('studies.labels.sku')"
                v-model="form.sku"
                outlined
              />
            </v-col>

            <!-- FM Referent -->
            <v-col cols="12">
              <SelectFMReferent
                @input="onInputFMReferent"
                :value="form.fm_referent"
              />
            </v-col>

            <!-- Reviewed by -->
            <v-col cols="12">
              <SelectFMReferent
                @input="onInputFMReviewed"
                :value="form.reviewed_by"
                :label="$t('studies.labels.reviewed_by')"
                :is-clearable="true"
              />
            </v-col>

            <!-- Project impact -->
            <v-col cols="12">
              <SelectProjectImpact
                :is-multiple="false"
                :value="form.projectImpact"
                :account-id="accountId"
                :is-required="true"
                @input="onInputProjectImpact"
              />
            </v-col>

            <!-- Weight -->
            <v-col cols="12">
              <v-text-field
                outlined
                hide-details
                type="number"
                v-model="form.weight"
                :rules="requiredRules"
                :label="$t('studies.labels.weight')"
              />
            </v-col>

            <!-- Color Code -->
            <v-col cols="12">
              <v-text-field
                outlined
                v-model="form.colorCode"
                :label="$t('studies.labels.colorCode')"
                hide-details
              />
            </v-col>

            <!-- Colors -->
            <v-col cols="12">
              <v-combobox
                :items="form.colors"
                outlined
                v-model="form.colors"
                :label="$t('studies.labels.colors')"
                chips
                deletable-chips
                multiple
                hide-details
              />
            </v-col>

            <!-- Produced quantity -->
            <v-col cols="12">
              <v-text-field
                outlined
                hide-details
                type="number"
                v-model="form.produced_quantity"
                :label="$t('studies.labels.produced_quantity')"
              />
            </v-col>

            <!-- Brand collection -->
            <v-col cols="12">
              <SelectBrandCollection
                :is-multiple="false"
                :value="form.brandCollection"
                :account-id="accountId"
                @input="onInputBrandCollection"
              />
            </v-col>

            <!-- Manufacturers -->
            <v-col cols="12">
              <SelectSupplier
                :value="form.suppliers"
                :is-required="true"
                :return-object="true"
                :is-multiple="true"
                :label="$tc('studies.labels.manufacturers', 2)"
                @input="onInputManufacturers"
              />
            </v-col>

            <!-- Raw composition -->
            <v-col cols="12">
              <v-textarea
                v-model="form.raw_composition"
                :label="$t('studies.labels.raw_composition')"
                outlined
              />
            </v-col>

            <!-- Showcase settings view type -->
            <v-col cols="12">
              <v-select
                outlined
                hide-details
                :label="$t('studies.labels.showcaseSettings.viewType')"
                :items="$t('constants.showcaseViewTypes.list')"
                item-value="value"
                item-text="text"
                v-model="form.showcaseSettings.viewType"
              />
            </v-col>

            <!-- Showcase settings hasProductDetails -->
            <v-col cols="12">
              <v-switch
                outlined
                hide-details
                :label="$t('studies.labels.showcaseSettings.hasProductDetails')"
                v-model="form.showcaseSettings.hasProductDetails"
              />
            </v-col>

            <!-- Showcase settings hasMaterials -->
            <v-col cols="12">
              <v-switch
                outlined
                hide-details
                :label="$t('studies.labels.showcaseSettings.hasMaterials')"
                v-model="form.showcaseSettings.hasMaterials"
              />
            </v-col>

            <!-- Showcase settings hasTraceability -->
            <v-col cols="12">
              <v-switch
                outlined
                hide-details
                :label="$t('studies.labels.showcaseSettings.hasTraceability')"
                v-model="form.showcaseSettings.hasTraceability"
              />
            </v-col>

            <!-- Showcase settings hasImpacts -->
            <v-col cols="12">
              <v-switch
                outlined
                hide-details
                :label="$t('studies.labels.showcaseSettings.hasImpacts')"
                v-model="form.showcaseSettings.hasImpacts"
              />
            </v-col>

            <!-- Showcase settings hasRecyclability -->
            <v-col cols="12">
              <v-switch
                outlined
                hide-details
                :label="$t('studies.labels.showcaseSettings.hasRecyclability')"
                v-model="form.showcaseSettings.hasRecyclability"
              />
            </v-col>

            <!-- Showcase redirection type -->
            <v-col cols="12">
              <v-select
                outlined
                :items="$t('constants.showcaseRedirectionTypes.list')"
                :label="$t('studies.labels.showcaseSettings.redirectionType')"
                hide-details
                v-model="form.showcaseSettings.redirectionType"
              />
            </v-col>

            <!-- State -->
            <v-col cols="12">
              <v-select
                outlined
                :items="$t('constants.studyStates.list')"
                :label="$t('studies.labels.state')"
                hide-details
                v-model="form.state"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions>
        <ButtonBarFormDialog
          class="mx-3"
          :is-loading-btn-save="isLoadingBtn.btnSave"
          @submit="customSubmit"
          @cancel="close"
        />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialog";
import formRules from "@/mixins/formRules";
import SelectAccount from "../Common/Inputs/SelectAccount";

export default {
  name: "StudyDialog",

  mixins: [dialogMixin, formRules],

  props: {
    product: { type: Object, default: () => null },
  },

  components: {
    SelectAccount,
    SelectProjectImpact: () =>
      import("@/components/Common/Inputs/SelectProjectImpact"),
    SelectSupplier: () => import("@/components/Common/Inputs/SelectSupplier"),
    SelectFMReferent: () =>
      import("@/components/Common/Inputs/SelectFMReferent"),
    SelectBrandCollection: () =>
      import("@/components/Common/Inputs/SelectBrandCollection"),
    SelectProduct: () => import("@/components/Common/Inputs/SelectProduct"),
    SelectComponent: () => import("@/components/Common/Inputs/SelectComponent"),
    AlertBar: () => import("@/components/Common/Bars/AlertBar"),
    ButtonBarFormDialog: () =>
      import("@/components/Common/Buttons/ButtonBarFormDialog"),
  },

  mounted() {
    if (this.product && this.product.account && this.product.account.id)
      this.accountId = this.product.account.id;
    if (this.item && this.item.account) this.accountId = this.item.account.id;
  },

  data() {
    return {
      isLoadingBtn: { btnSave: false },
      accountId: null,

      defaultForm: {
        sku: "",
        suppliers: [],
        brandCollection: null,
        projectImpact: null,
        fm_referent: {
          id: this.$session.get("id"),
          person: this.$session.get("person"),
        },
        reviewed_by: null,
        state: "PLANNED",
        colors: [],
        weight: 0,
        produced_quantity: 0,
        raw_composition: "",
        type: "STANDARD",
        kind: "PRODUCT",
        product: null,
        component: null,
        account: null,
        showcaseSettings: {
          viewType: "STANDARD",
          hasProductDetails: true,
          hasMaterials: true,
          hasTraceability: true,
          hasImpacts: true,
          hasRecyclability: true,
          redirectionType: "DISPLAY_ERROR",
        },
      },
    };
  },

  methods: {
    customSubmit() {
      if (this.$refs.form.validate()) {
        this.isLoadingBtn.btnSave = true;

        // Object to string id for
        this.shortcutFormObjects([
          "fm_referent",
          "reviewed_by",
          "projectImpact",
          "brandCollection",
          "suppliers",
          "account",
          "product",
          "component",
        ]);
        this.form.weight = Number(this.form.weight);
        this.form.produced_quantity = Number(this.form.produced_quantity);

        if (this.item) this.submitEdit();
        else this.submitAdd();
      }
    },

    submitAdd() {
      this.$http
        .post(`/studies`, this.form, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("studies.mainDialog.add.success")
          );
          this.close(true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    submitEdit() {
      this.$http
        .put(`/studies/${this.item.id}`, this.form, {
          headers: { Authorization: "Bearer " + this.$session.get("jwt") },
        })
        .then((res) => {
          this.$store.commit(
            "toast/showSuccess",
            this.$t("studies.mainDialog.edit.success")
          );
          this.close(true);
        })
        .catch((err) => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtn.btnSave = false;
        });
    },

    onInputAccount(val) {
      this.form.account = val;
      this.accountId = val.id;
    },

    onInputFMReferent(val) {
      this.form.fm_referent = val;
    },

    onInputFMReviewed(val) {
      this.form.reviewed_by = val;
    },

    onInputProjectImpact(val) {
      this.form.projectImpact = val;
    },

    onInputBrandCollection(val) {
      this.form.brandCollection = val;
    },

    onInputManufacturers(val) {
      this.form.suppliers = val;
    },

    onInputProduct(val) {
      this.form.product = val;
      this.form.account = val.account;
      this.accountId = val.account.id;
    },

    onInputComponent(val) {
      this.form.component = val;
    },
  },

  computed: {},
};
</script>

<style scoped></style>
